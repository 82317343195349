




































import { Component, Vue } from 'vue-property-decorator';
import MainHeader from '@/shared/resources/components/MainHeader.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import SaleInvoiceDetails from '@/app/modules/documents/components/SaleInvoiceDetails.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import ApiSettings from '@/core/api/settings/ApiSettings';
import SaleInvoiceModel from '@/app/modules/documents/models/SaleInvoiceModel';

@Component({
  components: {
    Button,
    SaleInvoiceDetails,
    GridRow,
    GridCol,
    MainHeader,
  },
})
export default class InvoiceDetailsView extends Vue {
  /**
   * Data
   */
  private document: SaleInvoiceModel | null = null;

  /**
   * Getters
   */
  private get id(): number {
    return Number(this.$route.params.id);
  }

  private get title(): string {
    return this.document && this.document.number
      ? this.document.number
      : 'Faktura';
  }

  /**
   * Methods
   */
  private printDocument() {
    window.open(this.document?.printUrl(ApiSettings.rawToken), '_blank');
  }

  /**
   * Handlers
   */
  private onGet(document: SaleInvoiceModel) {
    this.document = document;
  }

  private onPrintButtonClick() {
    this.printDocument();
  }
}
